let resumeData = {
  "imagebaseurl": "https://github.com/choik305/2ndPortfolio.git",
  "name": "Kevin Choi",
  "role": "Full Stack Developer Developer",
  "roleDescription": " I like dabbling in various parts of frontend and backend development. I like to learn about new technologies, eat good food or hang out with great people in my free time.",
  "socialLinks":[
      {
        "name":"linkedin",
        "url":"https://www.linkedin.com/",
        "className":"fa fa-linkedin"
      },
      {
        "name":"github",
        "url":"http://github.com/",
        "className":"fa fa-github"
      },
      {
        "name":"skype",
        "url":"http://skype.com",
        "className":"fa fa-skype"
      }
    ],
  "aboutme":"I have finished my B.E. in Computer Science and have been working in industry for past few years.",
  "address":"India",
  "website":"https://www.codeclassifiers.com",
  "education":[
    {
      "UniversityName":"University of Nevada, Las Vegas",
      "specialization":"Hotel Management",
      "MonthOfPassing":"Jan",
      "YearOfPassing":"2018",
      "Achievements":"Some Achievements"
    },
    {
      "UniversityName":"Some University",
      "specialization":"Some specialization",
      "MonthOfPassing":"Jan",
      "YearOfPassing":"2018",
      "Achievements":"Some Achievements"
    }
  ],
  "work":[
    {
      "CompanyName":"Some Company",
      "specialization":"Some specialization",
      "MonthOfLeaving":"Jan",
      "YearOfLeaving":"2018",
      "Achievements":"Some Achievements"
    },
    {
      "CompanyName":"Some Company",
      "specialization":"Some specialization",
      "MonthOfLeaving":"Jan",
      "YearOfLeaving":"2018",
      "Achievements":"Some Achievements"
    }
  ],
  "skillsDescription":"Your skills here",
  "skills":[
    {
      "skillname":"HTML5"
    },
    {
      "skillname":"CSS"
    },
    {
      "skillname":"Reactjs"
    }
  ],
  "portfolio":[
    {
      "name":"project1",
      "description":"mobileapp",
      "imgurl":"images/portfolio/phone.jpg"
    },
    {
      "name":"project2",
      "description":"mobileapp",
      "imgurl":"images/portfolio/project.jpg"
    },
    {
      "name":"project3",
      "description":"mobileapp",
      "imgurl":"images/portfolio/project2.png"
    },
    {
      "name":"project4",
      "description":"mobileapp",
      "imgurl":"images/portfolio/phone.jpg"
    }
  ],
  "testimonials":[
    {
      "description":"This is a sample testimonial",
      "name":"Some technical guy"
    },
    {
      "description":"This is a sample testimonial",
      "name":"Some technical guy"
    }
  ]

}
export default resumeData